<template>
  <div class="legal-research-container">
    <button class="search-history-btn" @click="openSearchHistory">
      <span class="material-symbols-rounded search-history-icon">schedule</span>
      <span class="search-history-text">Search History</span>
    </button>
    <main class="main-content">
      <section class="search-section">
        <header class="search-header">
          <h2 class="search-title">Legislation Search</h2>
          <p class="search-description">
            Search for statutes, acts, or regulations across multiple
            jurisdictions.
          </p>
        </header>
        <div class="search-and-filter">
          <form class="search-form" @submit.prevent="performSearch">
            <div class="search-input-wrapper">
              <span class="material-symbols-rounded search-icon">search</span>
              <input
                type="text"
                class="search-input"
                v-model="statement"
                placeholder="Can second wife claim husband's insurance money?"
                aria-label="Search query"
              />
            </div>
            <button
              type="submit"
              class="search-submit-btn"
              @click="performSearch"
            >
              <span class="material-symbols-rounded search-submit-icon"
                >send</span
              >
            </button>
          </form>
          <div class="filter-section">
            <div class="jurisdiction-filter" @click="toggleJurisdiction">
              <div class="filter-header">
                <span class="material-symbols-rounded filter-icon me-2"
                  >language</span
                >
                <span class="filter-title">Jurisdiction</span>
                <span class="material-symbols-rounded dropdown-icon"
                  >arrow_drop_down</span
                >
              </div>

              <div class="selected-jurisdictions">
                <span
                  v-for="juri in selectedJurisdictions"
                  :key="juri"
                  class="jurisdiction-badge"
                  >{{ juri }}</span
                >
              </div>
            </div>
            <JurisdictionSelector
              v-if="showJurisdiction"
              @update-jurisdiction="handleUpdateJurisdiction"
              only-country
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="advanced-filters">
            <button class="filter-btn">
              <span class="material-symbols-rounded filter-icon me-2"
                >filter_list</span
              >
              <span>Filters :</span>
            </button>
            <!-- <span class="filter-badge cursor-pointer" @click="toggleDateBox">
              {{ isDateRangeValid }}

              <span class="material-symbols-rounded remove-icon"
                >keyboard_arrow_down</span
              >
            </span> -->

            <span class="filter-badge cursor-pointer" @click="toggleCitation">
              {{ types !== "" ? types : "Legislation Type" }}
              <span class="material-symbols-rounded remove-icon"
                >keyboard_arrow_down</span
              >
            </span>
          </div>
          <div class="advanced-filters">
            <!-- <button class="clearall-button" @click="clearAll">Clear</button> -->
            <button class="clearall-button" @click="resetAll">Reset</button>
          </div>
        </div>
      </section>
      <div v-if="showDateBox">
        <div class="search-card">
          <div class="section">
            <h6 class="public_title">Publication Date</h6>
            <div class="date-inputs">
              <input
                type="date"
                placeholder="Start Date"
                v-model="startDate"
                @input="clearYears"
                class="custom-date-input"
              />
              <input
                type="date"
                placeholder="End Date"
                v-model="endDate"
                @input="clearYears"
                class="custom-date-input"
              />
            </div>

            <div class="radio-group">
              <div class="radio-option">
                <input
                  type="radio"
                  id="last5"
                  value="5"
                  v-model="years"
                  @change="setYears"
                />
                <label for="last5">Last 5 Years</label>
              </div>
              <div class="radio-option">
                <input
                  type="radio"
                  id="last10"
                  value="10"
                  v-model="years"
                  @change="setYears"
                />
                <label for="last10">Last 10 Years</label>
              </div>
              <div class="radio-option">
                <input
                  type="radio"
                  id="last15"
                  value="15"
                  v-model="years"
                  @change="setYears"
                />
                <label for="last15">Last 15 Years</label>
              </div>
              <div class="radio-option">
                <input
                  type="radio"
                  id="anyDate"
                  value="any"
                  v-model="years"
                  @change="clearDates"
                />
                <label for="anyDate">Any Date</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showCitation">
        <div class="citation">
          <div class="section">
            <h6 class="public_title">Legislation Type</h6>
            <div class="radio-group">
              <div
                class="radio-option"
                v-for="type in legislation_types"
                :key="type"
              >
                <input
                  type="radio"
                  :id="type"
                  :value="type"
                  v-model="types"
                  @change="showCitation = false"
                />
                <label :for="type">{{ type }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import JurisdictionSelector from "./../JurisdictionSelector.vue";
import Auth from "@/store/Auth.js";
import Research from "@/store/research.js";
export default {
  name: "LegalResearchPage",
  components: {
    JurisdictionSelector,
  },
  data() {
    return {
      showJurisdiction: false,
      selectedJurisdictions: [],
      selectedCountries: [],
      showDateBox: false,
      showCitation: false,
      selectedDate: null,
      statement: "",
      minDate: new Date().toISOString().split("T")[0],
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        .toISOString()
        .split("T")[0],
      anyDate: false,
      startDate: "",
      endDate: "",
      years: "",
      publicationType: "",
      from_date: "",
      to_date: "",
      recentSearches: [
        "Can second wife claim husband's insurance money?",
        "Driving in a drunken state is a violation of law.",
        "Alcohol, driver, accident.",
        "Domestic violence.",
      ],

      legislation_types: [
        "Acts",
        "Legal Reports",
        "Statutes",
        "Bills",
        "Regulations",
        "Orders",
        "Bylaws",
        "Constitutional Laws",
        "Private Legislation",
        "Public Legislation",
        "International Treaties",
        "Emergency Legislation",
        "Enabling Legislation",
        "Others",
      ],
      types: "",
    };
  },
  watch: {
    startDate(newVal) {
      this.from_date = newVal;
    },
    endDate(newVal) {
      this.to_date = newVal;
    },
  },
  computed: {
    dateRange() {
      if (this.startDate && this.endDate) {
        return {
          from_date: this.startDate,
          to_date: this.endDate,
        };
      } else if (this.years) {
        this.setYears();
        return {
          from_date: this.startDate,
          to_date: this.endDate,
        };
      } else {
        return null;
      }
    },
    isDateRangeValid() {
      if (!this.dateRange || this.dateRange.from_date.includes("NaN")) {
        return "Date Range";
      }

      return `${this.dateRange.from_date} - ${this.dateRange.to_date}`;
    },
  },
  methods: {
    openSearchHistory() {
      this.$router.push({ name: "LegislationSearchHistory" });
    },
    toggleJurisdiction() {
      this.showJurisdiction = !this.showJurisdiction;
    },
    toggleDateBox() {
      this.showDateBox = !this.showDateBox;
    },
    setYears() {
      const currentYear = new Date().getFullYear();
      this.startDate = `${currentYear - this.years}-01-01`;
      this.endDate = `${currentYear}-12-31`;
    },
    clearYears() {
      this.years = "";
    },
    clearDates() {
      this.startDate = "";
      this.endDate = "";
    },
    toggleCitation() {
      this.showCitation = !this.showCitation;
    },
    handleUpdateJurisdiction(jurisdictions) {
      this.selectedJurisdictions = jurisdictions;
    },

    formatSelectedCourts(courts) {
      // Assuming `courts` is an array of court names
      return courts.join(", ");
    },
    removeJurisdiction(index) {
      // Remove the jurisdiction by index
      this.selectedJurisdictions.splice(index, 1);
    },
    // clearAll() {},
    resetAll() {
      this.showJurisdiction = false;
      this.showCitation = false;
      // this.showDateBox = false;
      // this.clearYears();
      // this.clearDates();
      this.selectedJurisdictions = [];
      this.statement = "";
      this.types = "";
    },
    performSearch() {
      const obj = {
        search_type: "and",
        statement: [this.statement],
        location_id: 1,
        legislation_type: this.types,
        language: "en",
        from_date: this.startDate,
        to_date: this.endDate,
        jurisdictions: this.selectedJurisdictions,
      };

      Auth.getMe()
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          switch (res.data.data.nexlaw_credits) {
            case undefined:
            case "undefined":
            case null:
            case "null":
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
              break;
          }
        })
        .then(() => {
          return Research.legislationSearch(obj);
        })
        .then((response) => {
          this.$toast.success(response.data.message);
          this.$router.push({
            name: "LegislationSearchResult",
            params: { id: response.data.data.id },
          });
          // this.currentPage = 1;
          // this.emptyInputs();
          // this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.legal-research-container {
  background: #fafbfc;
  padding-bottom: 163px;
}

.page-header {
  background: #fff;
  padding: 16px 20px;
}

.page-title {
  color: #383a3e;
  font: 600 24px Poppins, sans-serif;
}

.tabs-navigation {
  border-bottom: 1px solid #f2f3f3;
  background: #fff;
  padding-right: 12px;
}

.tabs-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tab-item {
  color: #86888d;
  font: 400 14px/24px Poppins, sans-serif;
  padding: 8px 16px;
  cursor: pointer;
}

.tab-item.active {
  border-bottom: 2px solid #0e4485;
  color: #0e4485;
}

.search-history-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #0e4485;
  color: #fff;
  border: none;
  border-radius: 4px;

  font: 500 16px Poppins, sans-serif;
  margin: 16px 32px 0 auto;
  padding: 4px 16px;
  cursor: pointer;
}

.search-history-icon {
  width: 24px;
  height: 24px;
}

.main-content {
  margin-top: 32px;
  padding: 0 20px;
}

.search-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 40px;
}

.search-header {
  text-align: center;
  margin-bottom: 32px;
}

.search-title {
  color: #383a3e;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
}

.search-description {
  color: #86888d;
  font-size: 14px;
  line-height: 24px;
}

.search-form {
  display: flex;
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  overflow: hidden;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 20px;
}

.search-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.search-input {
  border: none;
  flex-grow: 1;
  font: 400 16px/28px Poppins, sans-serif;
  color: #383a3e;
  padding: 8px 0;
}

.search-input::placeholder {
  font-size: 16px !important;
}

a {
  text-decoration: none;
}
.search-submit-btn {
  display: flex;
  align-items: center;
  background: #0e4485;
  border: none;
  opacity: 0.6;
  padding: 8px 16px;
  color: #fff;
  cursor: pointer;
}
.search-submit-btn:hover {
  opacity: 1;
}

.search-submit-icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.filter-section {
  position: relative;
  margin-top: 16px;
}

.jurisdiction-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.filter-header {
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #86888d;
}
.filter-header:hover {
  border-radius: 4px;
  padding: 5px 10px;
  background: #f2f3f3;
  border-radius: 4px;
}

.filter-icon,
.dropdown-icon {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
}

.selected-jurisdictions {
  display: flex;
  gap: 8px;
}

.jurisdiction-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f2f3f3;
  border: 1px solid #d1d2d5;
  border-radius: 1000px;
  padding: 0 8px;
  font-size: 12px;
  color: #86888d;
}

.remove-icon {
  font-size: 18px;
  cursor: pointer;
}

.jurisdiction-limit {
  font-size: 12px;
  color: #86888d;
  margin-top: 8px;
}

.advanced-filters {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  font: 500 14px Poppins, sans-serif;
  color: #86888d;
  cursor: pointer;
}

.filter-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  border: 1px solid #d1d2d5;
  border-radius: 1000px;
  padding: 0 8px;
  font-size: 14px;
  color: #86888d;
  position: relative;
}

@media (max-width: 991px) {
  .legal-research-container {
    padding-bottom: 100px;
  }

  .search-history-btn {
    margin-right: 10px;
  }

  .search-section {
    padding: 20px;
  }
}
</style>
<style scoped>
.search-card {
  width: 30%;
  margin-top: -3%;
  margin-left: 12%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.section {
  margin-bottom: 20px;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

.date-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.date-inputs input {
  width: 48%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-option input {
  margin-right: 8px;
}

.radio-option label {
  font-size: 14px;
  color: #666;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
  transform: scale(1.1);
  accent-color: var(--primary);
}

input[type="text"]::placeholder {
  color: #aaa;
  font-size: 12px;
}

/* Media query for responsive layout */
@media (max-width: 600px) {
  .search-card {
    width: 100%;
  }

  .date-inputs input {
    width: 100%;
    margin-bottom: 8px;
  }
}
.citation {
  margin-top: -3%;
  margin-left: 12%;
  /* margin-left: 22%; */
  width: 25%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.citation_input {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.citation_input::placeholder {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
}

.clearall-button {
  border: none;
  border-radius: 20px !important;
  color: #86888d;
  font-size: 14px;
  cursor: pointer;
}
</style>
